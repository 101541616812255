var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "v-card",
        {
          staticClass: "d-flex align-items-center my-4 pa-2",
          attrs: { rounded: "lg", flat: "", color: "grey lighten-4" },
        },
        [
          _c(
            "v-avatar",
            { staticClass: "mx-4 my-2", attrs: { size: "96", rounded: "lg" } },
            [
              _c("v-img", {
                attrs: {
                  "lazy-src": require("@/assets/images/profile-circle.png"),
                  src:
                    _vm.user.profilePicture != null &&
                    _vm.user.profilePicture != ""
                      ? _vm.user.profilePicture
                      : require("@/assets/images/profile.jpg"),
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "ms-4 me-0 d-flex flex-column" }, [
            _c("span", { staticClass: "my-1 f14 font-weight-bold" }, [
              _vm._v(
                " " + _vm._s(_vm.user.firstName + " " + _vm.user.lastName) + " "
              ),
            ]),
            _c("span", { staticClass: "my-1 f13 grey--text text--darken-1" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("Age:")) +
                  " " +
                  _vm._s(_vm.user.age) +
                  " " +
                  _vm._s(_vm.$t("Year")) +
                  " "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "d-flex justify-content-between mt-5" }, [
        _c("div", {}, [
          _c("span", { staticClass: "f16 fw-bold" }, [
            _vm._v(_vm._s(_vm.$t("Activity status"))),
          ]),
        ]),
        _c(
          "div",
          [
            _c("v-select", {
              staticClass: "rounded-pill fit",
              attrs: {
                items: _vm.option,
                mandatory: "",
                solo: "",
                dense: "",
                "hide-details": "",
              },
              model: {
                value: _vm.selected,
                callback: function ($$v) {
                  _vm.selected = $$v
                },
                expression: "selected",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "mt-2" },
          [
            _c("LineChartGenerator", {
              attrs: {
                "chart-options": _vm.chartOptions,
                "chart-data": _vm.chartData,
                "chart-id": _vm.chartId,
                "dataset-id-key": _vm.datasetIdKey,
                plugins: _vm.plugins,
                "css-classes": _vm.cssClasses,
                styles: _vm.styles,
                width: _vm.width,
                height: _vm.height,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._l(_vm.moods, function (mood, index) {
        return _c(
          "div",
          { key: index, staticClass: "d-flex align-items-center mt-2" },
          [
            _c(
              "v-avatar",
              { staticClass: "my-2", attrs: { size: "96", rounded: "circle" } },
              [
                _c(
                  "v-carousel",
                  {
                    attrs: {
                      "hide-delimiters": "",
                      cycle: "",
                      "show-arrows": false,
                      interval: 2000,
                      disabled: "",
                    },
                  },
                  _vm._l(mood.moodTypes, function (t, i) {
                    return _c(
                      "v-carousel-item",
                      {
                        key: i,
                        attrs: {
                          transition: "scale-transition",
                          "reverse-transition": "scale-transition",
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "img-fluid pa-5",
                          attrs: { src: t.resourceId },
                        }),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "ms-2 me-0" },
              [
                _vm._l(mood.moodTypes, function (t, i) {
                  return _c("span", { staticClass: "f14 font-weight-bold" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(t.title)) +
                        _vm._s(i != mood.moodTypes.length - 1 ? "، " : "") +
                        " "
                    ),
                  ])
                }),
                _c("span", { staticClass: "f13 d-block break-words" }, [
                  _vm._v(" " + _vm._s(mood.description) + " "),
                ]),
                _c(
                  "span",
                  { staticClass: "d-block my-1 f13 grey--text text--darken-1" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("moment")(
                            mood.updateDate,
                            "dddd , jD jMMMM jYYYY"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              2
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }